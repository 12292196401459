import { isAxiosError, AxiosError } from 'axios'

import useTranslationsStore from '@/stores/translations'
import { ErrorCodes } from '@/utilities/constants'
import { type TranslationKeys } from '@/types/translations'

type ResponseError = {
  errorSource: string
  httpCode: number
  code: number
  message: string
  errors?: {
    [key: string]: unknown
  }
}

const getApiErrorMessage = (err: unknown) => {
  const code: ErrorCodes | null = extractErrorCode(err)

  const translationsStore = useTranslationsStore()
  const { t } = translationsStore

  const errorCodeTranslationsMapper: Record<ErrorCodes, TranslationKeys> = {
    [ErrorCodes.OTP_INCORRECT]: 'verify_error_wrong_otp_code',
    [ErrorCodes.OTP_EXPIRED]: 'verify_error_otp_expired',
    [ErrorCodes.TERMINAL_DISABLED]: 'terminal_disabled_notification_info',
    [ErrorCodes.SESSION_ALREADY_ACTIVE]: 'session_already_active_notification_info',
    [ErrorCodes.DUPLICATE_DONGLE_ID]: 'terminal_duplicate_dongle_id_notification_info'
  }

  return (
    (code && errorCodeTranslationsMapper[code] && t(errorCodeTranslationsMapper[code])) ||
    (isAxiosError(err) && err.response?.data?.message) ||
    translationsStore.t('default_error_message')
  )
}

function extractErrorCode(err: unknown) {
  if (isAxiosError(err)) {
    const axiosError: AxiosError<ResponseError> = err

    if (axiosError.response && axiosError.response.data) {
      return axiosError.response.data.code ?? null
    }
  }

  return null
}

export { extractErrorCode, getApiErrorMessage }
