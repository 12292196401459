import env from '@/config/env'
import { type User, type BalanceData } from '@/types/user'
import { createAxiosInstance } from '@/api/http'
import { Headers } from '@/utilities/constants'

const http = createAxiosInstance(
  {
    baseURL: env.sevenPlatformMonolith
  },
  [Headers.CompanyUUID, Headers.Authorization]
)

const getUser = async (): Promise<User> => {
  const url = 'web/profile/register.json'

  const { data } = await http.get(url)
  return data
}

const getBalance = async (): Promise<BalanceData> => {
  const url = 'web/profile/balance.json'

  const { data } = await http.get(url)
  return data
}

export default { getUser, getBalance }
