import { useRouter } from 'vue-router'

import useUserStore from '@/stores/user'
import useAuthStore from '@/stores/auth'
import useModalStore from '@/stores/modal.ts'
import useNotificationsStore from '@/stores/notifications'
import useTranslationsStore from '@/stores/translations'
import useGravityGatewayStore from '@/stores/gravityGateway'
import usePlatformSocketClientStore from '@/stores/platformSocketClient'
import useStompClientStore from '@/stores/stompClient'
import { Routes } from '@/utilities/constants.ts'

const useAuth = () => {
  const router = useRouter()
  const authStore = useAuthStore()
  const userStore = useUserStore()
  const modalStore = useModalStore()
  const notificationsStore = useNotificationsStore()
  const translationsStore = useTranslationsStore()
  const gravityGatewayStore = useGravityGatewayStore()
  const platformSocketClientStore = usePlatformSocketClientStore()
  const stompClientStore = useStompClientStore()

  const handlePostLogoutActions = async () => {
    if (authStore.authData?.sessionId) {
      stompClientStore.unsubscribeSession(authStore.authData?.sessionId)
    }

    authStore.authData = null
    userStore.userData = null
    userStore.balanceData = null
    userStore.isCasinoGameOpen = false

    modalStore.closeAllModals()
    platformSocketClientStore.disconnect()
    notificationsStore.addNotification(translationsStore.t('log_out_notification_info'), 'warning')
    gravityGatewayStore.notifyAuthorizationChanged({ user: {}, token: null })
    await router.push({ name: Routes.QR_CODE })

    authStore.isCashoutLoading = false
  }

  const handleLogout = async () => {
    await authStore.cashout({
      sessionId: authStore.authData?.sessionId ?? ''
    })

    await handlePostLogoutActions()
  }

  const ensureStompConnection = () => {
    const sessionId = authStore.qrCodeLinkData?.sessionId
    const isSessionEqual = stompClientStore.currentSession === sessionId

    if (!stompClientStore.isClientActivated) {
      stompClientStore.init(sessionId)
    } else if (!isSessionEqual && sessionId) {
      stompClientStore.subscribeSession(sessionId)
    }
  }

  const initQRCodeAndStomp = async () => {
    await authStore.generateQRCode()
    ensureStompConnection()
  }

  return {
    handlePostLogoutActions,
    initQRCodeAndStomp,
    handleLogout
  }
}

export default useAuth
