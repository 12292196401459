<script setup lang="ts">
import { ref, onBeforeMount } from 'vue'
import { useRoute, useRouter, RouterView } from 'vue-router'
import { storeToRefs } from 'pinia'
import { SCToastNotificationGroup } from '@nsftx/seven-components'

import useAuth from '@/composables/useAuth'
import useSSBTEventMonitor from '@/stores/ssbtEventMonitor'
import useAuthStore from '@/stores/auth.ts'
import useNotificationsStore from '@/stores/notifications'
import useGravityGatewayStore from '@/stores/gravityGateway'
import useTranslationsStore from '@/stores/translations'
import { Routes } from '@/utilities/constants.ts'

const route = useRoute()
const router = useRouter()
const auth = useAuth()
const ssbtEventMonitor = useSSBTEventMonitor()
const authStore = useAuthStore()
const notificationsStore = useNotificationsStore()
const gravityGatewayStore = useGravityGatewayStore()
const translationsStore = useTranslationsStore()

const { notifications } = storeToRefs(notificationsStore)
const { isTerminalOutOfOrder } = storeToRefs(authStore)

const { initSSBTButtonsListener } = ssbtEventMonitor
const { init: initGravityGateway } = gravityGatewayStore
const { getTranslations } = translationsStore

const isAppLoading = ref(false)

const initNetworkListeners = () => {
  window.addEventListener('online', async () => {
    if (route.name === Routes.QR_CODE) await auth.initQRCodeAndStomp()
  })

  window.addEventListener('offline', () => {
    isTerminalOutOfOrder.value = true

    if (route.name === Routes.OTP) router.push({ name: Routes.QR_CODE })
  })
}

onBeforeMount(async () => {
  initSSBTButtonsListener()
  initNetworkListeners()

  isAppLoading.value = true
  initGravityGateway()
  await getTranslations()
  isAppLoading.value = false
})
</script>

<template>
  <RouterView v-show="!isAppLoading" />

  <SCToastNotificationGroup v-model="notifications" position="bottom" />
</template>
