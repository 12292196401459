import { createAxiosInstance } from '@/api/http'
import env from '@/config/env'
import { type GetApplicationConfigParams, type ApplicationConfigData } from '@/types/config'

const http = createAxiosInstance(
  {
    baseURL: env.apiBaseUrl
  },
  [],
  {
    withBasicAuth: true
  }
)

// TODO: implement properly when api gets made
const getApplicationSettings = async (
  params: GetApplicationConfigParams
): Promise<ApplicationConfigData> => {
  // const { payload } = params
  // const url = 'api/v1/session/timeout-settings'

  //  const { data } = await http.post(url, payload)
  // return data

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        autoLogout: {
          lowBalance: {
            minBalance: 1,
            countdown: 55,
            openDelay: 15
          },
          inactivity: {
            countdown: 155,
            openDelay: 55,
            enabled: true
          }
        }
      })
    }, 2000)
  })
}

export default { getApplicationSettings }
