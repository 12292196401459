import { computed, ref } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import GatewayMaster from '@nsftx/seven-gravity-gateway/master.js'

import useSSBTEventMonitor from '@/stores/ssbtEventMonitor'
import useUserStore from '@/stores/user'
import useAuthStore from '@/stores/auth'
import useNotificationsStore from '@/stores/notifications'
import useTranslationsStore from '@/stores/translations'
import useModalStore from '@/stores/modal'
import vaixHttp from '@/api/vaix.ts'
import {
  type SlaveEventMessage,
  type NotifyRouteChangedParams,
  type AnalyticsSendEvent,
  GatewayEvent,
  GatewayEmitEvent,
  SlaveEvent
} from '@/types/gravityGateway'
import env from '@/config/env'

const useGravityGatewayStore = defineStore('gravity-gateway', () => {
  const gateway = ref<typeof GatewayMaster>(null)

  const ssbtEventMonitor = useSSBTEventMonitor()
  const userStore = useUserStore()
  const authStore = useAuthStore()
  const notificationsStore = useNotificationsStore()
  const translationsStore = useTranslationsStore()
  const modalStore = useModalStore()

  const { authData, isAuthenticated } = storeToRefs(authStore)
  const { userData, isCasinoGameOpen } = storeToRefs(userStore)
  const { modalState } = storeToRefs(modalStore)

  const { restartUserInactivityTimeout } = ssbtEventMonitor
  const { addNotification } = notificationsStore
  const { t } = translationsStore

  const initialSlaveUserData = computed(() => ({
    currency: {
      symbol: userData.value?.currency ?? env.tenant.currency,
      virtualSymbol: false
    },
    locale: {
      iso1: userData.value?.language ?? env.languageISO1,
      iso2: userData.value?.languageISO6392 ?? env.languageISO2
    },
    referrerUrl: window.encodeURIComponent(window.location.origin),
    settings: null,
    tenant: {
      name: env.tenant.name,
      uuid: env.tenant.uuid,
      country: env.tenant.country
    },
    user: {
      auth: {
        token: authData.value?.jwt
      },
      profile: {
        token: userData.value?.token,
        oddsDisplay: userData.value?.oddsDisplay,
        timezoneOffset: userData.value?.timezoneOffset
      },
      balance: userData.value?.balance,
      email: userData.value?.email,
      firstName: userData.value?.firstName,
      id: userData.value?.id,
      lastName: userData.value?.lastName,
      logged: isAuthenticated.value,
      name: userData.value?.username,
      nickname: userData.value?.nickname,
      uuid: userData.value?.uuid,
      country: userData.value?.country
    }
  }))

  const handleGameOpenImpression = (message: SlaveEventMessage) => {
    let game

    if (message.event === 'Game.Open') game = message.data.game
    if (!game || message.event !== SlaveEvent.GameOpen || !game.isVaixEvent) return

    vaixHttp.sendVaixAnalytics({
      data: {
        user_id: userData.value?.uuid ?? null,
        game_id: game.productDisplayId,
        position: game.position ? game.position.toString() : null,
        page: game.page,
        swim_lane: game.swimLane || '',
        channel: 'SSBT'
      },
      event_type: 'clicks:game',
      user_id: userData.value?.uuid ?? null
    })
  }

  const init = () => {
    const casinoAppURLOrigin = new URL(env.casinoAppUrl).origin
    const allowedOrigins = [casinoAppURLOrigin]
    if (env.isDevMode) {
      allowedOrigins.push('http://localhost:*')
    }

    gateway.value = GatewayMaster({
      slaves: {},
      allowedOrigins,
      debug: env.isDevMode
    })

    setListeners()
  }

  const notifyAuthorizationChanged = (auth: unknown) => {
    sendMessage(GatewayEmitEvent.UserAuthorizationChanged, { auth })
  }

  const notifyBalanceChanged = (balance: number) => {
    sendMessage(GatewayEmitEvent.UserBalanceChanged, { balance })
  }

  const notifyRouteChanged = (data: NotifyRouteChangedParams) => {
    sendMessage(GatewayEmitEvent.RouteChanged, data)
  }

  const sendMessage = (action: GatewayEmitEvent, data: unknown) => {
    gateway.value?.emit('PluginSevenCasino', {
      slaveId: 'PluginSevenCasino',
      frameId: 'PluginSevenCasino',
      action,
      data
    })
  }

  const removeSevenCasinoSlave = () => {
    gateway.value.removeSlave('PluginSevenCasino')
  }

  const addSevenCasinoSlave = () => {
    gateway.value.addSlave({
      slaveId: 'PluginSevenCasino',
      frameId: 'PluginSevenCasino',
      autoResize: false,
      data: initialSlaveUserData.value,
      init: () => {},
      loaded: () => {
        if (isAuthenticated.value) {
          addNotification(
            `${t('general_welcome')} ${initialSlaveUserData.value.user.nickname}`,
            'success'
          )
        }
      }
    })
  }

  const setListeners = () => {
    gateway.value?.subscribe(GatewayEvent.SlaveEvent, (message: SlaveEventMessage) => {
      const eventHandler: Record<SlaveEvent, () => void> = {
        [SlaveEvent.GameOpen]: () => (isCasinoGameOpen.value = true),
        [SlaveEvent.GameClose]: () => (isCasinoGameOpen.value = false),
        [SlaveEvent.Click]: () => {
          restartUserInactivityTimeout()
        }
      }

      eventHandler[message.event]?.()

      handleGameOpenImpression(message)
    })

    gateway.value?.subscribe(GatewayEvent.AnalyticsSend, (message: AnalyticsSendEvent) => {
      if (message.data.type !== 'VaixImpressions') return

      const content = message.data.content[0]

      vaixHttp.sendVaixAnalytics({
        data: {
          user_id: userData.value?.uuid ?? null,
          game_ids: content.value.gameIds,
          page: content.value.page,
          swim_lane: content.id,
          channel: 'SSBT'
        },
        event_type: 'impressions:games',
        user_id: userData.value?.uuid ?? null
      })
    })

    gateway.value?.subscribe(GatewayEvent.DocumentChangeMeta, () => {})

    gateway.value?.subscribe(GatewayEvent.DocumentChangeTitle, () => {})

    gateway.value?.subscribe(GatewayEvent.RouterRouteChanged, () => {})

    gateway.value?.subscribe(GatewayEvent.UserLoginRequired, () => {
      modalState.value.loginRequired = true
    })
  }

  return {
    initialSlaveUserData,
    init,
    notifyAuthorizationChanged,
    notifyBalanceChanged,
    notifyRouteChanged,
    addSevenCasinoSlave,
    removeSevenCasinoSlave
  }
})

export default useGravityGatewayStore
