import { useTimeoutFn } from '@vueuse/core'
import { defineStore } from 'pinia'

import useModalStore from '@/stores/modal.ts'
import useGravityGatewayStore from '@/stores/gravityGateway.ts'
import useAuthStore from '@/stores/auth.ts'
import useConfigStore from '@/stores/config'

import { type SSBTButtonEventDetail, SSBTButtons } from '@/types/events.ts'

const useSSBTEventMonitorStore = defineStore('ssbt-event-monitor', () => {
  const gravityGatewayStore = useGravityGatewayStore()
  const modalStore = useModalStore()
  const authStore = useAuthStore()
  const configStore = useConfigStore()

  let startUserInactivityTimeout = () => {}
  let stopUserInactivityTimeout = () => {}

  const restartUserInactivityTimeout = () => {
    stopUserInactivityTimeout()
    startUserInactivityTimeout()
  }

  const initUserInactivityTimeout = () => {
    const { start, stop } = useTimeoutFn(
      () => {
        stopUserInactivityTimeout()
        modalStore.modalState.inactivity = true
      },
      (configStore.appSettings?.autoLogout.inactivity.openDelay ?? 0) * 1000,
      {
        immediate: false
      }
    )

    startUserInactivityTimeout = start
    stopUserInactivityTimeout = stop

    initSSBTButtonsListener()
    restartUserInactivityTimeout()
  }

  const initSSBTButtonsListener = () => {
    window.addEventListener('adpkeyevent', (event: CustomEvent<SSBTButtonEventDetail>) => {
      restartUserInactivityTimeout()

      switch (event.detail.keydown) {
        case SSBTButtons.Menu:
          modalStore.closeAllNonPreservedModals()

          gravityGatewayStore.notifyRouteChanged({
            params: {
              param1: 'all',
              param2: ''
            }
          })
          break
        case SSBTButtons.Cashout:
          if (!authStore.isAuthenticated) return

          modalStore.modalState.logout = true
          break
      }
    })
  }

  return {
    initSSBTButtonsListener,
    initUserInactivityTimeout,
    startUserInactivityTimeout,
    stopUserInactivityTimeout,
    restartUserInactivityTimeout
  }
})

export default useSSBTEventMonitorStore
