type CommonAnalyticsData = {
  user_id: string | null
  page: string
  swim_lane: string
  channel: 'SSBT'
}

type ImpressionsData = CommonAnalyticsData & {
  game_ids: string[]
}

type ClicksData = CommonAnalyticsData & {
  game_id: string
  position: string | null
}

export type VaixAnalyticsParams =
  | {
      data: ImpressionsData
      event_type: 'impressions:games'
      user_id: string | null
    }
  | {
      data: ClicksData
      event_type: 'clicks:game'
      user_id: string | null
    }

export enum SSBTButtons {
  Menu = 'menu',
  Cashout = 'cashout'
}

export interface SSBTButtonEventDetail {
  keydown: SSBTButtons
}
