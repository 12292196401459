import env from '@/config/env'
import { createAxiosInstance } from '@/api/http'
import { Headers } from '@/utilities/constants.ts'
import { type VaixAnalyticsParams } from '@/types/events.ts'

const http = createAxiosInstance(
  {
    baseURL: env.vaix.url
  },
  [Headers.Authorization, Headers.VaixClientID],
  {
    isVaix: true
  }
)

const sendVaixAnalytics = async (params: VaixAnalyticsParams) => {
  const url = 'api/tracker/events'

  await http.post(url, params, {
    withCredentials: false
  })
}

export default { sendVaixAnalytics }
