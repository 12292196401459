import { ref } from 'vue'
import { defineStore } from 'pinia'

const initialModalState = {
  loginRequired: false,
  logout: false,
  lowBalance: false,
  inactivity: false
}

type ModalKey = keyof typeof initialModalState

const preservedModals: Readonly<ModalKey[]> = ['lowBalance']

const useModalStore = defineStore('modal', () => {
  const modalState = ref({ ...initialModalState })
  const timeouts: Map<ModalKey, number> = new Map()

  const openModalWithDelay = (name: ModalKey, delay: number) => {
    if (timeouts.has(name) || modalState.value[name]) return

    const timeoutId = window.setTimeout(() => {
      modalState.value[name] = true

      timeouts.delete(name)
    }, delay)

    timeouts.set(name, timeoutId)
  }

  const closeDelayedModal = (name: ModalKey) => {
    if (timeouts.has(name)) {
      clearTimeout(timeouts.get(name))
      timeouts.delete(name)
    }

    modalState.value[name] = false
  }

  const closeAllModals = () => {
    timeouts.forEach((id) => clearTimeout(id))
    timeouts.clear()

    modalState.value = { ...initialModalState }
  }

  const closeAllNonPreservedModals = () => {
    modalState.value = (Object.keys(initialModalState) as ModalKey[]).reduce(
      (acc, key) => {
        acc[key] = preservedModals.includes(key) ? modalState.value[key] : false

        if (timeouts.has(key) && !preservedModals.includes(key)) {
          clearTimeout(timeouts.get(key))
          timeouts.delete(key)
        }

        return acc
      },
      { ...initialModalState }
    )
  }

  return {
    modalState,
    openModalWithDelay,
    closeDelayedModal,
    closeAllModals,
    closeAllNonPreservedModals,
    timeouts
  }
})

export default useModalStore
