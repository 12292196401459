import router from '@/router'
import env from '@/config/env'
import { Routes } from '@/utilities/constants'

const config = {
  dongleId: '',
  userLanguage: env.userLanguage
}

const populateConfigFromQueryParams = () => {
  const { dongleId, lang } = router.currentRoute.value.query

  if (typeof dongleId === 'string') config.dongleId = dongleId
  if (typeof lang === 'string') config.userLanguage = lang
}

const ensureQRCodeRoute = async () => {
  if (router.currentRoute.value.name !== Routes.QR_CODE) {
    await router.push({ name: Routes.QR_CODE })
  }
}

const handleInitialConfig = async () => {
  await router.isReady()

  populateConfigFromQueryParams()
  await ensureQRCodeRoute()
}

export { handleInitialConfig }
export default config
