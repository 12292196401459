import { ref } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { useThrottleFn } from '@vueuse/core'
import io, { Socket } from 'socket.io-client'

import useUserStore from '@/stores/user'
import useAuthStore from '@/stores/auth'
import useModalStore from '@/stores/modal'
import useGravityGatewayStore from '@/stores/gravityGateway'
import useConfigStore from './config'
import { initURLWithParams } from '@/utilities/url'

type SocketEvent = {
  data: []
  meta: {
    contentEncoding: 'plaintext'
    contentType: 'json'
  }
  sentTime: number
  type: 'balanceUpdate'
}
type InitParams = { userUUID: string; token: string; username: string; url: string }

const usePlatformSocketClientStore = defineStore('platform-socket-client', () => {
  const userStore = useUserStore()
  const authStore = useAuthStore()
  const modalStore = useModalStore()
  const gravityGatewayStore = useGravityGatewayStore()
  const configStore = useConfigStore()

  const { userData, isBalanceBelowThreshold } = storeToRefs(userStore)
  const { isAuthenticated } = storeToRefs(authStore)
  const { appSettings } = storeToRefs(configStore)

  const { getBalance } = userStore
  const { openModalWithDelay, closeDelayedModal } = modalStore

  const socket = ref<typeof Socket | null>()

  const initSocketListeners = (userUUID: string) => {
    socket.value?.on('connect', () => {
      socket.value?.emit('subscribe', {
        channel: userUUID,
        subChannels: {
          language: 'sr-Latn',
          deliveryPlatform: 'Web'
        }
      })

      socket.value?.on(userUUID, async (event: SocketEvent) => {
        const eventHandler = {
          balanceUpdate: async () => {
            await throttledGetBalanceAndNotifySlave()

            if (isBalanceBelowThreshold.value) {
              openModalWithDelay(
                'lowBalance',
                (appSettings.value?.autoLogout.lowBalance.openDelay ?? 0) * 1000
              )
            } else {
              closeDelayedModal('lowBalance')
            }
          }
        }

        eventHandler[event.type]?.()
      })
    })
  }

  const init = (config: InitParams) => {
    const { userUUID, url, token, username } = config

    const socketParams = {
      token,
      username,
      clientType: 'user',
      clientSubType: 'Player'
    }

    const socketUrl = initURLWithParams(url, socketParams)

    socket.value = io(socketUrl)

    initSocketListeners(userUUID)
  }

  const disconnect = () => {
    socket.value?.disconnect()
    socket.value = null
  }

  /**
   * 7Platform monolith has rate limiter with max 20 requests per minute
   */
  const throttledGetBalanceAndNotifySlave = useThrottleFn(
    async () => {
      if (!isAuthenticated.value) return

      const { notifyBalanceChanged } = gravityGatewayStore

      await getBalance()

      if (userData.value?.balance) {
        notifyBalanceChanged(userData.value.balance)
      }
    },
    3000,
    true
  )

  return {
    socket,
    init,
    disconnect
  }
})
export default usePlatformSocketClientStore
