import { createRouter, createWebHistory } from 'vue-router'

import { Routes } from '@/utilities/constants'
import env from '@/config/env'

const router = createRouter({
  history: createWebHistory(env.baseUrl),
  routes: [
    {
      path: '/',
      component: () => import('@/layouts/LoginLayout.vue'),
      children: [
        {
          path: '',
          name: Routes.QR_CODE,
          component: () => import('@/views/ScanQRCode.vue')
        },
        {
          path: '/otp/:sessionId',
          name: Routes.OTP,
          component: () => import('@/views/EnterOTP.vue')
        }
      ]
    },
    {
      path: '/lobby',
      name: Routes.LOBBY,
      component: () => import('@/layouts/LobbyLayout.vue')
    }
  ]
})

export default router
