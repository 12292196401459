import { ref } from 'vue'
import { defineStore } from 'pinia'

import useNotificationsStore from '@/stores/notifications'
import configApi from '@/api/config'
import { type ApplicationConfigData } from '@/types/config'
import config from '@/config'

const useConfigStore = defineStore('config', () => {
  const notificationsStore = useNotificationsStore()

  const { handleErrorNotification } = notificationsStore

  const appSettings = ref<ApplicationConfigData | null>(null)

  const getApplicationSettings = async () => {
    try {
      const payload = { dongleId: config.dongleId }
      appSettings.value = await configApi.getApplicationSettings({ payload })
    } catch (err) {
      handleErrorNotification(err)
    }
  }

  return {
    appSettings,
    getApplicationSettings
  }
})

export default useConfigStore
